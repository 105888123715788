export const PROJECT_ID = 'onlyone-prod';
export const CLIENT_ID = '49883709625-5qbsk82nqd1nb6mjvgt8ulufj4cb2sel.apps.googleusercontent.com';

export const BASE_URL = 'https://api.onlyonecard.io';
export const REDIRECT_URL = 'https://backend.onlyonecard.io';
export const API_KEY = 'AIzaSyAojs5-1_7HK3fGdPoSSjI_OO0xWxJ9a_E';
export const BACKEND_API_BASE_URL = 'https://jarvis.onlyonecard.io';
export const FRIDAY_API_BASE_URL = 'https://friday.onlyonecard.io';
export const CEREBRO_API_BASE_URL = 'https://cerebro.onlyonecard.io';
export const SMAUG_API_BASE_URL = 'https://smaug.onlyonecard.io';
export const SIDEKICK_API_BASE_URL = 'https://sidekick.onlyonecard.io';
export const NOTIF_CENTER_API_BASE_URL = 'https://notif-center.onlyonecard.io';
export const SAURON_API_BASE_URL = 'https://sauron.onlyonecard.io';
export const SCROOGE_API_BASE_URL = 'https://scrooge.onlyonecard.io';

export const ONLYONE_WEB_APP_BASE_URL = 'https://app.onlyonecard.io';
